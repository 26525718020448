<template>
    <div>
        <van-nav-bar
          title="特殊情况申请进度"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
        />
        <van-steps direction="vertical" :active="datas.active">
          <van-step v-for="(x,index) in datas.list" :key="index" class="vst">
            <h3>【{{x.EXT01}}】{{x.APPROVER}}{{x.TASKUSER ? '('+x.TASKUSER+')' : ''}}</h3>
            <p>{{x.ACTION}} {{x.COMMENTS ? x.COMMENTS : ''}}</p>
            <p>{{x.ENDTIME ? x.ENDTIME : ''}}</p>
          </van-step>
        </van-steps>
    </div>
</template>
<script>
import {progress} from "@/api/user";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
import $ from  'jquery'
export default {
    name:'ContactsProgress',
    data() {
        return {
            params:this.$route.params,
            datas:{},
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
        _this.getProgress();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, shareDesc)
            }
        },
        onClickLeft() {
            history.go(-1);
        },
        async getProgress() {
            let _this = this;
            const res = await progress({id:_this.params.id});
            if (res.code == 1) {
                _this.datas = res.data;
            }
        }
    }
}

</script>